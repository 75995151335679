import React, { useState } from 'react';
import './imageassets.css';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Modal from '@material-ui/core/Modal';
import { getModalStyle } from './fileUploadHelper';
import { MdCheckBox } from 'react-icons/md';

const ImageUploader = (props) => {
	const { id, title, imageUrl, uploadStatus, errorMessage, onFileUpload, onDeleteHandler, amplitude, mitraId, leadId } = props;
	const [open, setOpen] = useState(false);
	const [modalStyle] = useState(getModalStyle);

	const onUploadHandler = (event, id) => {
		let file = event.target.files[0];
		if (file) {
			if (file && file.size > 10000000) {
				alert('This image is too big! Please send a smaller image (< 10 MB)');
			} else {
				let eventProps =  { file: file };
				if(mitraId && leadId)
					eventProps = Object.assign({mitraId: mitraId, leadId: leadId}, eventProps);
				amplitude.getInstance().logEvent('File Attached', eventProps);
				onFileUpload({ file, id });
			}
		}
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleDelete = (event, id) => {
		onDeleteHandler(id);
		setOpen(false);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = (type) => {
		let eventProps =  { type: type };
		if(mitraId && leadId)
			eventProps = Object.assign({mitraId: mitraId, leadId: leadId}, eventProps);
		amplitude.getInstance().logEvent('File Upload Clicked', eventProps);
	}

	const isInProgress = (status) => {
		return uploadStatus === 'Uploading' || uploadStatus === 'Verifying' || uploadStatus === 'Deleting';
	}
	return (
		<React.Fragment>
			<div className="filesubcomponent">
				<div className="dl-title-wrapper">
					<div className="styleHeader">{title}</div>
					{uploadStatus === "Verified" ? (
						<MdCheckBox className="check-tick" />) : null}
				</div>

				<div className={`imageHolder ${id}`}>
					{imageUrl && <img src={imageUrl} alt={title} className="actualImagestyle" />}
					{(isInProgress(uploadStatus) || uploadStatus === 'error') && (
						<div className="messageoverlay" />
					)}
					{(isInProgress(uploadStatus) && <div className="loader" />)}
					{isInProgress(uploadStatus) && (
						<div className="messageText">{uploadStatus}</div>
					)}
					{uploadStatus === 'error' && <div className="errorMessageText">{errorMessage}</div>}
				</div>
				{uploadStatus !== 'Verified' ? (
					<div className="iconStyle">
						<label onClick={handleClick(id)} htmlFor={id}>+</label>
						<input
							type="file"
							id={id}
							className="inputFile"
							accept="image/*"
							value=""
							onChange={(data) => onUploadHandler(data, id)}
						/>
					</div>
				) : (
						<div className="deleteIcon" onClick={handleOpen}>
							<DeleteOutlinedIcon />
						</div>
					)}
				<Modal open={open} onClose={handleClose}>
					{
						<div style={modalStyle}>
							<div className="deleteModalTextStyle"> Delete Photo? </div>
							<div>
								<button className="buttonStyle" onClick={(event) => handleDelete(event, id)}>
									DELETE
								</button>
								<button className="buttonStyle" onClick={handleClose}>
									NO
								</button>
							</div>
						</div>
					}
				</Modal>
			</div>
		</React.Fragment>
	);
};
export default ImageUploader;
