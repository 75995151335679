export const idKeys = [
  "dlImageFront",
  "dlImageBack",
  "aadhaarFront",
  "aadhaarBack",
  "panCard",
  "rc",
  "bankPassbook",
  "cancelledCheque",
];
const imageTitleMap = {
  dlImageFront: "DL Front",
  dlImageBack: "DL Back",
  aadhaarFront: "Aadhaar Front",
  aadhaarBack: "Aadhaar Back",
  panCard: "PAN",
  rc: "RC",
  bankPassbook: "Bank Passbook",
  cancelledCheque: "Cancelled Cheque",
};
export const docTypeAPICall = {
  dlImageFront: "DL Image",
  dlImageBack: "DL Image",
  aadhaarFront: "Aadhaar Image",
  aadhaarBack: "Aadhaar Image",
  panCard: "PAN Image",
  rc: "Vehicle RC Image",
  bankPassbook: "Bank Passbook Image",
  cancelledCheque: "Bank Cancelled Cheque Image",
};
const getFormattedData = (data) => {
  let outputResponse = {};
  if (data) {
    if (data["DL Image"]) {
      outputResponse["dlImageFront"] = data["DL Image"].split('||')[0];
      outputResponse["dlImageBack"] = data["DL Image"].split('||')[1];
    }
    if (data["Aadhaar Image"]) {
      outputResponse["aadhaarFront"] = data["Aadhaar Image"].split('||')[0];
      outputResponse["aadhaarBack"] = data["Aadhaar Image"].split('||')[1];
    }
    outputResponse["panCard"] = data["PAN Image"];
    outputResponse['rc'] = data['Vehicle RC Image'];
    outputResponse['bankPassbook'] = data['Bank Passbook Image'];
    outputResponse['cancelledCheque'] = data["Bank Cancelled Cheque Image"]
  }
  return outputResponse;
}

export const fetchUserDocumentHelper = (response = {}) => {
  let data = getFormattedData(response);
  let userData = {
    count: 0,
  };
  idKeys.forEach((key) => {
    userData[key] = {
      title: imageTitleMap[key],
      imageUrl: data[key] ? data[key] : "",
      uploadStatus: data[key] ? "Verified" : "noImage",
      errorMessage: "",
    };
    userData.count = data[key] ? userData.count + 1 : userData.count;
  });
  return userData;
};
export const getModalStyle = () => {
  const top = 40;
  const left = 42;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute",
    height: `113px`,
    width: `280px`,
    boxShadow: "0px 2px 2px 0px #000",
    backgroundColor: "#FFF",
  };
};

export const multiImageIdValidator = {
  dlImageFront: ["dlImageFront", "dlImageBack"],
  dlImageBack: ["dlImageFront", "dlImageBack"],
  aadhaarFront: ["aadhaarFront", "aadhaarBack"],
  aadhaarBack: ["aadhaarFront", "aadhaarBack"],
};
