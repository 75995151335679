import React from 'react';
import TopMenu from '../TopMenu';
import axios from 'axios';
import { fetchUserDocumentHelper } from './fileUploadHelper';
import ImageUploaderContainer from './ImageUploaderComponent';
import { idKeys, multiImageIdValidator, docTypeAPICall } from './fileUploadHelper';
import * as profileService from '../../service/profileService';

import uuidv4 from 'uuid/v4';

class DocUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mitraId: null,
			leadId: null,
			userId: localStorage.getItem('userId'),
			userDocuments: {},
			job: null,
			name: 'Hi',
			phoneNumber: null
		};
	}

	componentDidMount() {
		const mitraId = new URL(window.location.href).searchParams.get('mitraId');
		const leadId = new URL(window.location.href).searchParams.get('leadId');
		if (mitraId && leadId)
			this.fetchMitra(mitraId, leadId).then(res => {
				this.setState({
					mitraId: mitraId,
					leadId: leadId,
					userId: res.userId,
					name: res.name,
					phoneNumber: res.phoneNumber
				});
				this.fetchDocs(res.userId);
				this.props.amplitude.getInstance().setUserId(res.userId);
				this.props.amplitude.getInstance().logEvent('Document upload page visited',
					{ page: window.location.href, phoneNumber: res.phoneNumber, mitraId: mitraId, leadId: leadId });
			});
		else
			this.fetchUser().then(res => {
				if (res.ifUserNew) {
					window.location.href = "/";
				}
				this.setState({ userId: res.userId });
				this.fetchDocs(res.userId);
				const profile = profileService.getProfile(res.userId);
				this.setState({ name: profile.firstName });
				this.props.amplitude.getInstance().setUserId(res.userId);
				this.props.amplitude.getInstance().logEvent('Document upload page visited',
					{ page: window.location.href, phoneNumber: profile.phoneNumber });
				this.fetchName(res.userId);
				this.updateLocalStorage(res);
			});
		this.fetchJob();
	}

	updateLocalStorage = (userChatHistory) => {
		if (userChatHistory.ifLocalStorageToBeCleared) {
			localStorage.clear();
		}
		localStorage.setItem('userId', userChatHistory.userId);
		localStorage.setItem('phoneNumber', userChatHistory.phoneNumber);
	}

	fetchMitra = (mitraId, leadId) => {
		return profileService.getUserForLead(mitraId, leadId);
	}

	fetchUser = () => {
		let userId = localStorage.getItem('userId') || 0;
		const phone = new URL(window.location.href).searchParams.get('phone') || 0;
		const verificationToken = new URL(window.location.href).searchParams.get('token') || 0;
		let locallyStoredMsgCount = Number(localStorage.getItem('messagesCount')) || 0;
		return profileService.getUserChatHistory(userId,
			phone,
			verificationToken,
			locallyStoredMsgCount);
	}

	fetchJob = () => {
		const jobId = new URL(window.location.href).searchParams.get('jobId');
		if (jobId) {
			this.getJob(jobId).then((response) => {
				this.setState({ job: response });
			});
		}
	}

	fetchDocs = (userId) => {
		this.getVerifiedIDs(userId).then((response) => {
			if (response) {
				const docs = fetchUserDocumentHelper(response);
				this.setState({
					userDocuments: docs
				});
			}
		});
	}

	getJob = async (jobId) => {
		let url = `${process.env.REACT_APP_BACKEND_URL}/job/${jobId}`;
		return fetch(url).then((res) => res.json());
	};

	getVerifiedIDs = async (userId) => {
		let url = `${process.env.REACT_APP_BACKEND_URL}/getVerifiedDocuments?userId=${userId}`;
		return await fetch(url).then((res) => res.json());
	};

	uploadAPICall = ({ file, id }) => {
		return new Promise((resolve) => {
			this.onUploadStatusChange(id, 'Uploading');
			const uniqueId = uuidv4();
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async (event) => {
				let url = process.env.REACT_APP_BACKEND_URL + '/uploadCompressed';
				const res = await this.uploadFile({ file, uniqueId }, url);
				if (res.status !== 200) {
					this.onUploadStatusChange(id, 'error', null, 'Sorry Something Went Wrong. Try Again Later.');
				} else {
					if (res.data.imageUrl) {
						this.onUploadStatusChange(id, 'Uploaded', res.data.imageUrl);
						resolve({ imageUrl: res.data.imageUrl, id });
					}
				}
			};
			reader.onerror = (error) => {
				console.log(error);
				this.onUploadStatusChange(id, 'error', null, 'Sorry Something Went Wrong. Try Again Later.');
			};
		});
	};

	onUploadStatusChange = (id, status, imageUrl, errorMessage) => {
		let updateData = this.state.userDocuments;
		updateData[id]['uploadStatus'] = status;
		if (status === 'Verified') {
			updateData.count = updateData.count + 1;
		}
		if (imageUrl) {
			updateData[id]['imageUrl'] = imageUrl;
		}
		if (errorMessage) {
			updateData[id]['errorMessage'] = errorMessage;
		}
		this.setState({
			userDocuments: updateData
		});
	};

	multiDocValidator = (id) => {
		let filelist = [];
		multiImageIdValidator[id].forEach((key) => {
			if (this.state.userDocuments[key].imageUrl) {
				filelist.push(this.state.userDocuments[key].imageUrl);
			}
		});
		return filelist;
	};

	onFileUploadHandler = async ({ file, id }) => {
		const data = await this.uploadAPICall({ file, id });
		if (data && data.imageUrl) {
			let eventProps = { type: id };
				if (this.state.mitraId && this.state.leadId)
					eventProps = Object.assign({ mitraId: this.state.mitraId, leadId: this.state.leadId }, eventProps);
				this.props.amplitude.getInstance().logEvent('File Uploaded', eventProps);
			if (multiImageIdValidator[id]) {
				let otherId = multiImageIdValidator[id].filter((key) => key !== id)[0];
				let multiUrl = this.multiDocValidator(id);
				if (multiUrl.length === 2) {
					this.verifyDocumentAPICall({
						imageUrl: multiUrl[0],
						otherSideImageUrl: multiUrl[1],
						id,
						otherId: otherId
					});
				}
			} else {
				this.verifyDocumentAPICall({ imageUrl: data.imageUrl, id });
			}
		}
	};

	verifyDocumentAPICall = async ({ imageUrl, id, otherSideImageUrl, otherId }) => {
		this.onUploadStatusChange(id, 'Verifying');
		if (otherId) {
			this.onUploadStatusChange(otherId, 'Verifying');
		}
		const userId = this.state.userId;
		const url = process.env.REACT_APP_BACKEND_URL + '/uploadID';
		const data = new FormData();
		data.append('imageUrl', imageUrl);
		data.append('userId', userId);
		data.append('type', docTypeAPICall[id]);
		if (this.state.mitraId) {
			data.append('isLead', true);
		}
		if (otherSideImageUrl) {
			data.append('otherSideImageUrl', otherSideImageUrl);
		}
		let errorMessage;
		try {
			const res = await axios.post(url, data);
			if (res.data.userProof.idDetected === true) {
				let eventProps = { type: docTypeAPICall[id] };
				if (this.state.mitraId && this.state.leadId)
					eventProps = Object.assign({ mitraId: this.state.mitraId, leadId: this.state.leadId }, eventProps);
				this.props.amplitude.getInstance().logEvent('Upload Successful', eventProps);
				this.onUploadStatusChange(id, 'Verified', null, '');
				if (otherId) {
					this.onUploadStatusChange(otherId, 'Verified', null, '');
				}
				return;
			}
			if (res.data.userProof.isImageReadable === false) {
				errorMessage = 'Image is blurry. Please upload a clear image.';
			} else if (res.data.userProof.isIdVerifiedWithDB === false || res.data.userProof.metaData === null) {
				errorMessage = 'Image is duplicate. Please upload your own document.';
			} else {
				errorMessage = 'Image is not a valid document. Please upload another document.';
			}
			this.onUploadStatusChange(id, 'error', null, errorMessage);
			if (otherId)
				this.onUploadStatusChange(otherId, 'error', null, errorMessage);
		} catch (error) {
			errorMessage = 'Sorry Something Went Wrong. Try Again Later.';
			this.onUploadStatusChange(id, 'error', null, errorMessage);
			if (otherId)
				this.onUploadStatusChange(otherId, 'error', null, errorMessage);
		}
	};

	uploadFile = async (file, url) => {
		const data = new FormData();
		data.append('file', file.file);
		data.append('uniqueId', file.uniqueId);
		data.append('userId', this.state.userId);
		const res = await axios.post(url, data);
		return res;
	};

	onDeleteHandler = (type) => {
		this.onUploadStatusChange(type, 'Deleting');
		if (multiImageIdValidator[type]) {
			const otherSideType = multiImageIdValidator[type].filter((key) => key !== type)[0];
			this.onUploadStatusChange(otherSideType, 'Deleting');
		}
		let url = `${process.env.REACT_APP_BACKEND_URL}/deleteDocument?userId=${this.state.userId}&type=${docTypeAPICall[type]}`;
		return fetch(url, {
			method: 'post'
		}).then(response => this.fetchDocs(this.state.userId));
	};


	render() {
		return (
			<div className="doc-upload-wrapper">
				<TopMenu job={this.state.job}
					name={this.state.name}
					phoneNumber={this.state.phoneNumber}
					amplitude={this.props.amplitude} />
				<div className="progressheader">
					<div className="progressBarContainer">
						<div
							className="progress-bar progress-bar-striped"
							style={{
								width: `${Math.floor(this.state.userDocuments.count / 8 * 100)}%`
							}}
						/>
					</div>
					<div className="progressText">{`${Math.floor(this.state.userDocuments.count / 8 * 100)}%`}</div>
				</div>
				<div className="filemaincontainer">
					{console.log(this.state)}
					{idKeys.map((item) => (
						<ImageUploaderContainer
							amplitude={this.props.amplitude}
							title={this.state.userDocuments[item] && this.state.userDocuments[item].title}
							imageUrl={this.state.userDocuments[item] && this.state.userDocuments[item].imageUrl}
							uploadStatus={
								this.state.userDocuments[item] && this.state.userDocuments[item].uploadStatus
							}
							errorMessage={
								this.state.userDocuments[item] && this.state.userDocuments[item].errorMessage
							}
							onFileUpload={this.onFileUploadHandler}
							uploadFile={this.uploadFile}
							id={item}
							key={item}
							mitraId={this.state.mitraId}
							leadId={this.state.leadId}
							onDeleteHandler={() => this.onDeleteHandler(item)}
						/>
					))}
				</div>
			</div>
		);
	}
}

export default DocUpload;
